var $class="se2--table-caption-read-more",$name="tableCaptionReadMore",$path="app/partials/tableCaptionReadMore/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

/**
 * The number represends a threshold of the minimum height of the content that is required in
 * order to render the "show more" button.
 *
 * Keep in sync with index.scss > .read-more-content > max-height
 */
const minContentHeightInPixels = 400;

const readMoreContent = 'read-more-content';
const readMoreButtonWrapper = 'read-more-button-wrapper';
const readMoreButtonCaption = 'read-more-button-caption';
const moreContentShown = 'more-content-shown';

const readMoreTextFallback = 'Read more';
const readLessTextFallback = 'Read less';

export default shell.registerPartial($this, ({ addEventListener, mount, subscribeToMessage, configs }) => {
  function showMoreContent(content, buttonCaption) {
    content.classList.remove('more-content-hidden');
    content.classList.add(moreContentShown);
    buttonCaption.innerHTML = buttonCaption.dataset?.readLessText || readLessTextFallback;
    buttonCaption.classList.add('open');
  }

  function hideMoreContent(content, buttonCaption) {
    content.classList.remove(moreContentShown);
    content.classList.add('more-content-hidden');
    buttonCaption.innerHTML = buttonCaption.dataset?.readMoreText || readMoreTextFallback;
    buttonCaption.classList.remove('open');
  }

  function registerReadMoreButton(element) {
    const readMoreButton = element.querySelector(`.${readMoreButtonWrapper}`);

    if (readMoreButton == null) {
      // Read more button has been disabled.
      return;
    }

    const content = element.querySelector(`.${readMoreContent}`);
    const buttonCaption = readMoreButton.querySelector(`.${readMoreButtonCaption}`);

    const contentHeight = content?.clientHeight;

    if (content == null || contentHeight < minContentHeightInPixels) {
      // The content height is lower than the threshold of minContentHeightInPixels. Therefore,
      // we remove the read more button as we consider it "short" content anyway.
      readMoreButton.classList.add(configs.css.classNames.hide);
      content.classList.add('hide-shadow');
      return;
    }

    hideMoreContent(content, buttonCaption);

    addEventListener(readMoreButton, 'click', () => {
      if (!content.classList.contains(moreContentShown)) {
        showMoreContent(content, buttonCaption);
      } else {
        hideMoreContent(content, buttonCaption);
      }
    });
  }

  mount((element) => {
    registerReadMoreButton(element);

    subscribeToMessage(configs.messages.sortTableData,
      (dataContentId) => {
        const newElement = document.querySelector(`[data-content-id='${dataContentId}']`);
        registerReadMoreButton(newElement);
      });
  });
});
