var $class="se2--container-results",$name="ContainerResults",$path="app/components/ContainerResults/index.js",$this={$class,$name,$path,};import ajax from 'app/modules/ajax';
import shell from 'app/modules/shell';
import analytics from 'app/modules/analytics';

import 'app/partials/table';
import 'app/partials/select';
import 'app/partials/tableCaptionReadMore';

import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

export const dropdownSelectorClassName = 'input-select';
export const nestedContentClassname = 'nested-content';
const componentContentTableClassName = 'se2--nested-content-table';
const additionalFilter = 'additional-filter';

export default shell.registerComponent($this, ({ addEventListener, publishMessage, mount, configs }) => {
  const selectedOptions = {};
  let ajaxUrl = null;
  let nestedContentHolder = null;

  mount((element) => {
    const dropdownElements = element.querySelectorAll(`.${dropdownSelectorClassName}`);
    dropdownElements[1]?.classList.add(additionalFilter);

    const dropdownElement = element.querySelector(`.${dropdownSelectorClassName}`);
    const additionalDropDown = element.querySelector(`.${dropdownSelectorClassName}.${additionalFilter}`);
    nestedContentHolder = element.querySelector(`.${nestedContentClassname}`);
    const { filterColumnName } = nestedContentHolder.dataset;
    const filterColumnNameRegExp = new RegExp(filterColumnName, 'gi');

    ajaxUrl = element.dataset.ajaxUrl;
    selectedOptions.year = element.dataset.preloadedTableId;

    const getColumnIndex = () => findIndex(
      nestedContentHolder
        .querySelectorAll('section:not(.hide) thead th'),
      (cell) => filterColumnNameRegExp.test(cell.innerText),
    );

    const onAdditionalSelectChange = ({ target }) => {
      nestedContentHolder.querySelectorAll('tbody tr')
        .forEach((row) => {
          row.classList.remove(configs.css.classNames.hide);
          if (row.querySelectorAll('td')[getColumnIndex()].innerText !== target.value && target.value) {
            row.classList.add(configs.css.classNames.hide);
          }
        });
    };

    const updateSelect = () => {
      if (!additionalDropDown) return;
      if (getColumnIndex() === -1) {
        additionalDropDown.selectedIndex = 0;
        additionalDropDown.disabled = true;
        return;
      }
      additionalDropDown.disabled = false;
      const updatedSelectArray = filter(
        uniq(
          map(nestedContentHolder.querySelectorAll('section:not(.hide) tbody tr'),
            ((row) => {
              row.classList.remove(configs.css.classNames.hide);
              return row.querySelectorAll('td')[getColumnIndex()].innerText;
            })),
        ), (text) => text,
      );

      if (updatedSelectArray.length === 0) additionalDropDown.disabled = true;
      additionalDropDown.innerHTML = [
        additionalDropDown.firstElementChild.outerHTML,
        ...updatedSelectArray.map((text) => `<option value="${text}">${text}</option>`)].join(' ');
    };

    function showRelatedContentData(target) {
      const selectedContentId = target.value;
      const resultsItems = target.closest(`.${$class}`)
        .querySelectorAll(`.${componentContentTableClassName}`);
      forEach(resultsItems,
        (result) => {
          result.classList.toggle(configs.css.classNames.hide, result.dataset.contentId !== selectedContentId);
        });
      updateSelect();
    }

    function contentWasAdded(id) {
      return !!nestedContentHolder.querySelector(`.${componentContentTableClassName}[data-content-id="${id}"]`);
    }

    function onSelectChange({ target }) {
      if (selectedOptions[target.name] === target.value || !target.value) {
        return;
      }

      selectedOptions[target.name] = target.value;
      const yearSelectedContentId = selectedOptions.year;
      if (ajaxUrl && !contentWasAdded(target.value)) {
        ajax.fetchText(`${ajaxUrl}?tableId=${yearSelectedContentId}`).then(
          (content) => {
            if (content) {
              nestedContentHolder.insertAdjacentHTML('beforeend', content);
              const table = nestedContentHolder.querySelector(`.${componentContentTableClassName}:last-child`);
              table.setAttribute('data-content-id', target.value);

              analytics.setUpOnDownloadLinkClickTracker(table);
              showRelatedContentData(target);
              publishMessage(
                configs.messages.sortTableData, target.value,
              );
            }
          },
          () => {},
        );
      } else {
        showRelatedContentData(target);
      }
    }

    updateSelect();
    addEventListener(dropdownElement, 'change', onSelectChange);
    addEventListener(additionalDropDown, 'change', onAdditionalSelectChange);
  });
});
